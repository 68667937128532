@import "mixins";

[id^="AttachmentsContainer"], .iframeAttachmentImageBoxDialog {
  #gridViewContainer {
    border-top: 1px solid #DEDEDE; }
  #imageBoxDialog {
    .content {
      max-width: 1000px;
      width: auto;
      min-width: 500px;
      max-height: calc(90vh - 50px);
      .imageCanvas-js {
        text-align: center;
        max-width: 960px;
        img {
          max-width: 940px; } } } }
  [id^="imageBoxDialog_redesigned"] {
    .lightbox {
      width: 75%;
      height: 75%;
      left: 12.5%;
      top: 12.5%;
      margin-top: unset!important;
      margin-left: unset!important; }
    .fullPage {
      position: sticky;
      width: 100%;
      height: 100%; }
    .display {
      width: 100%;
      margin: 0;
      padding-bottom: 10px;
      .span6 {
        margin: 0; }
      .information {
        display: flex;
        align-items: center;
        label {
          line-height: 26px !important; } }
      .actions {
        float: right;
        label:has(input[type=checkbox][disabled]) {
          cursor: default!important; }
        #image-rotation {
          button {
            background: #1976D2;
            border: unset;
            font-size: 10pt; }
          button:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px; }
          button:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px; }
          button:not(:first-child)::before {
            content: unset; } } } }
    .content {
      height: calc(100% - 70px);
      width: unset!important;
      max-height: unset!important;
      overflow: hidden;
      .imageCanvas-js {
        overflow: auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 40px);
        img {
          max-width: 100%;
          max-height: 100%;
          position: relative;
          top: 0;
          left: 0;
          height: auto; } }
      .imageCanvas-js {
        img {
          transform-origin: center; }
        img.zoom-scrolling-y {
          transform-origin: top; }
        img.zoom-scrolling-x {
          transform-origin: top left; }
        img.zoom-scrolling-xy {
          transform-origin: left top; } } } }

  [id^="attachmentFilesContainer"] {
    border: 2px dashed rgba(0,0,0,0);
    &.highlightDrag {
      border: 2px dashed $lightSimproBlue;
      background: #FFF; } }
  .ui-state-highlight {
    font-weight: bold; }
  .resetSearchButton {
    margin-left: -30px;
    margin-top: 5px;
    display: block;
    color: #CCCCCC;
    &:hover {
      color: $lightSimproBlue; } } }

[id^="attachmentContainerWrapper"] {
  .rootDropArea {
    .attachmentDrop {
      border: 2px dashed #efe7e7;
      font-weight: normal;
      font-size: 15px;
      width: 100%;
      padding: 10px 0 10px 0;
      margin-bottom: 10px;
      height : 20px;
      &.highlightDrag {
        border: 2px dashed $lightSimproBlue; }
      color: #B8B8B8; } } }

.blur-attachmentTable {
  filter: blur(20px) brightness(80%) contrast(120%);
  transition: filter 0.3s ease; }

.attachmentDropTable {
  border: 10px solid #cbcbcb !important; }

.attachmentContainer {
  .plupload {
    left: auto !important;
    right: 0;
    border: 1px solid red;
    padding: 10px;
    display: none !important; } }

.attachmentDraggable {
  z-index: 4; }

#zoomPanel {
  position: absolute;
  right: 40px;
  bottom: 45px;
  button.zoom-out, button.zoom-in {
    padding-top: 4px; }
  button {
    height: 35px; }
  .zoom-out, .zoom-in {
    font-size: 15pt; }
  .zoom-reset {
    font-size: 13pt;
    span {
      padding-right: 5px; } } }

.attachmentDrop td {
  font-weight: bold;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
  &.small {
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 12px; } }

table.attachmentTable {
  tbody tr td {
    &.highlightDrag {
      background: rgba(0, 174, 239, 0.12); } } }

.draggingArea {
  background: #FFF;
  &.highlightDrag {
    border: 2px dashed #B3B1B1; }
  &.activeDrag {
    border: 2px dashed #00aeef; } }

.attachmentThumbnail {
  background: #eee;
  margin: 10px 10px 5px 5px;
  overflow: hidden;
  position: relative;
  float: left;
  min-width: 120px;
  min-height: 110px;
  img {
    height: 104px; }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.icon-file {
      font-size: 76px;
      position: relative;
      top: 10px; }
    &.extension {
      font-weight: 500;
      font-size: 16px;
      position: absolute;
      bottom: 10px;
      line-height: 1.5em;
      left: 7%;
      width: 30%;
      margin: 0 30%;
      background: #fff; } } }

.attachmentOptions {
  text-align: right;
  padding: 0; }

.button-container {
  display: flex;
  gap: 2px;
  justify-content: flex-end;
  right: 32px;
  position: absolute; }

.attachmentsModalButtonSmall {
  color: #FFFFFF;
  max-width: 50px;
  border: 0; }
.attachmentsModalButtonSmall.download-js {
  background: #1976D2; }
.attachmentsModalButtonSmall.fullscreen-js {
  background: #0E527F; }
.attachmentsModalButtonSmall.delete-js {
  background: #DE104F; }

.modalNavigation {
  position: absolute;
  top: 50%;
  border: none;
  background: transparent;
  &:disabled {
    visibility: hidden; } }

.modalNavigation.previous {
  left: -70px;
  z-index: 4; }

.modalNavigation.next {
  right: -70px;
  z-index: 4; }

#labelAttachmentExifData {
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  color: #6C757D;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default; }


.attachmentViewSwitch {
  &:hover {
    color: $lightBlue; } }

.attachmentViewBreadcrumb {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  width: inherit;
  position: absolute;
  transition: width ease-in 0.15s;
  &.expandBreadCrumb:hover {
    background: rgba(255,255,255,0.7);
    width: 95.75%;
    z-index: 100;
    border-bottom-width: 1px; }
  a {
    padding: 4px 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    font-weight: 500;
    &.highlightDrag {
      color: #00AEEF;
      border: 1px dashed #00AEEF;
      background: rgba(0, 174, 239, 0.12);
      padding: 4px 2px; } }
  span {
    display: inline-block;
    padding: 4px 0;
    &.divider {
      a {
        color: #777;
        padding: 4px 0; } } }
  a.inactive {
    color: $bodyTextColour;
    cursor: default; } }

.thumbnailHolder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center; }

.folderContainer {
  float: left;
  text-align: center;
  position: relative;
  background: #eee;
  margin: 10px 10px 5px 5px;
  padding: 10px 10px 5px 10px;
  min-height: 122px;
  width: 150px;
  .icon-folder {
    font-size: 8em; }
  .dragFolder {
    &:not(.noHighlight) {
      &:hover {
        .icon-folder {
          color: $lightSimproBlue; } } } }
  &.highlightDrag {
    border: 2px dashed #EAEAEA;
    .dragFolder:not(.noHighlight) {
      .icon-folder {
        color: $simproBlue; } } }
  &.highlightDrag {
    .dragFolder:not(.noHighlight) {
      .icon-folder {
        color: $simproBlue; } } } }

.searchResults {
  padding: 20px!important;
  .icon-search {
    margin-right: 10px;
    background: #FFFFFF;
    border-radius: 50%;
    padding: 6px;
    color: #00AEEF;
    border: 1px solid #00AEEF; } }

.fileName {
  display: inline-block;
  max-width: 120px;
  line-height: 1em; }

.moreIconFileName {
  font-size: 18px!important;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  position: absolute;
  bottom: 10px;
  right: 5px; }

.editAttachmentItem-js {
  line-height: 1em; }

.gridListIcons * {
  padding: 0;
  display: inline-block !important;
  vertical-align: middle;
  line-height: 20px !important; }

.toggleAttachmentViewOption {
  color: $bodyTextColour;
  &.enabled {
    color: #DCDEDF;
    cursor: pointer;
    &:hover {
      color: $lightSimproBlue; } } }

.attachmentMultiOptions {
  margin-left: 14px; }

.attachmentImageOptionsCheckbox {
  right: 5px;
  position: absolute;
  display: inline; }

.attachmentImageOptionsMore {
  display: inline; }

.contextMenuAttachments {
  a {
    font-size: 12px!important; }
  .contextMenuSpan {
    * {
      line-height: 11px;
      vertical-align: middle;
      font-size: 12px!important; } } }

.contextMenuAttachments {
  .contextMenuSpan:hover {
    background: inherit !important;
    color: #000 !important; } }

.showIconGridView {
  position: absolute;
  right: 0;
  padding: 2px 5px 0;
  font-size: 14px;
  cursor: default;
  background: #eee;
  border-radius: 0 0 0 3px; }

// profile Image related changes
#imagePlaceholderProfileImage {
  .browseAttachment {
    border-radius: 100%;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    label {
      display: block;
      padding: 5em 15px 4.5em 15px;
      line-height: inherit;
      text-align: center;
      width: 120px; } }

  #imageDisplayPlaceHolder {
    img {
      width: 150px;
      heght: 150px; } }

  .prebuildImageHeight {
    height: 200px;
    width: 150px;
    margin: 0 auto;
    .topDiv {
      height: 150px; }
    img {
      height: 150px; } } }

#defaultProfileImage {
  position: relative;
  &.loadingProfileImage:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: #FFF;
    z-index: 1;
    padding: 50px 25px; } }

.profileImage {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  vertical-align: bottom;
  object-fit: cover; }

.empMarkerIconPin {
  background-size: cover;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #ddd;
  position: relative;
  box-shadow: 2px 2px 4px #333;
  top: -38px;
  right: 11px;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 15px solid #ddd;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0 auto; } }
.uploadifive-button {
  display: none; }

// customer portal logo image
#imagePlaceholderLogoImage {
  display: flex;
  flex-flow: column;
  .browseAttachment {
    height: 30px;
    width: 350px;
    margin: 5px auto;
    background-color: $simproBlue;
    label {
      display: block;
      padding: 0 15px;
      text-align: center;
      width: 250px; } }

  #imageDisplayPlaceHolder {
    padding: 5px;
    flex-grow: 1;
    img {
      height: 20px;
      padding-top: 10px; } } }

#imagePlaceholderLogoImage {
  position: relative;
  border: #0a0a0a 1px solid; }


.logoImage {
  width: 100px;
  height: 100px;
  vertical-align: middle; }

.buttonsGroup {
  .zoomDisabled {
    color: grey;
    cursor: default; } }

.mainAttachmentWindow {
  .header {
    height: 35px;
    padding-top: 2px; } }

.imageViewerAttachmentName {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%; }

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 24px;
  &.switch--compact {
    width: 45px;
    height: 25px;
    .slider:before {
      height: 20px;
      width: 20px; }
    input.checked:not([readonly]), input:checked:not([readonly]) {
      &+ .slider:before {
        transform: translateX(18px); } } }
  & input {  // Hide default HTML checkbox
    display: none; }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DCDEDF;
    -webkit-transition: .5s;
    transition: .5s;
    &:before {
      position: absolute;
      content: "";
      height: 19px;
      width: 19px;
      left: 3px;
      bottom: 2.57px;
      background-color: white;
      transition: .5s;
      z-index: 1; }
    &:focus-visible {
      outline: 1px solid #ccc;
      outline-offset: 1px;
      transition: auto; } } }
.switch-inline {
  top: 8px; }

.switch.disabled {
  opacity: 0.3;
  background-color: inherit !important; }

.switch-on, .switch-off {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: white;
  top: -1px; }

.switch-on {
  left: 8px;
  display: none; }

.switch-off {
  right: 8px;
  color: #262626; }

input.checked, input:checked {
  & + .slider {
    background-color: $simproBlue;
    &:focus-visible {
      outline: 1px solid $simproBlue;
      outline-offset: 1px;
      transition: auto; } }

  &:not([readonly]) + .slider:before {
    transform: translateX(38px);
    left: 5px; }

  & ~ .switch-on {
    display: block; }

  & ~ .switch-off {
    display: none; } }

.switchLabelAlign {
  margin-top: 13px; }

.leftSwitchLabelAlign {
  padding-top: 5px;
  margin-left: 0px !important; }

.switch-inline {
  top: 8px; }

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%; } }

/* Small sliders */
.switch {
  &.switch--small {
    width: 34px;
    height: 14px;
    line-height: 18px;
    .slider:before {
      width: 10px;
      height: 10px;
      left: 2px;
      bottom: 1.74px; }
    input.checked:not([readonly]), input:checked:not([readonly]) {
      &+ .slider:before {
        transform: translateX(17px); } }
    .switch-on, .switch-off {
      font-size: 9px; }
    .switch-on {
      left: 4px; }
    .switch-off {
      right: 4px; } } }

/* Medium sliders */
.switch {
  &.switch--medium {
    width: 40px;
    height: 16px;
    line-height: 20px;
    .slider:before {
      width: 12px;
      height: 12px;
      left: 2px;
      bottom: 1.74px; }
    input.checked:not([readonly]), input:checked:not([readonly]) {
      &+ .slider:before {
        transform: translateX(21px); } }
    .switch-on, .switch-off {
      font-size: 10px; }
    .switch-on {
      left: 6px; }
    .switch-off {
      right: 6px; }
    &.switch-inline {
      top: 3px; } } }

@import "mixins";

@media print {
  @page {
    margin: 1cm 0;
    size: landscape; }

  /* Results Table
   *TODO: IE9+ supports table-cell,last:child and :not. Add alternate classes and css for earlier support if needed for this functionality*/
  table {
    &.resultsTable {
      td:first-child input, &.handler td.sortableHandle + td input, tr:not(.greyRow) td:last-child, th:last-child {
        display: none; } }
    &.resultsTable td table {
      td:last-child, th:last-child {
        display: table-cell !important; } }
    &.internalTable tr {
      td:last-child, th:last-child {
        display: table-cell !important; } } }
  /* /////////////////*/

  #content_container {
    -webkit-print-color-adjust: exact;
    border: 0 solid #ccc;
    @include border_radius(0);
    &.dashboardContainer {
      border: 0;
      #content {
        background-color: transparent; } } }
  #content {
    @include border_radius(0); }
  #main, #mainContainer {
    margin: 0 !important;
    padding: 0 !important; }
  body, td, th, div, p, li, input, textarea, #sPanel {
    font-size: 12px !important; }
  body {
    overflow: visible!important; }
  td {
    border: 1px solid #ccc !important;
    padding: 4px !important; }
  th {
    border: 1px solid #ccc !important;
    padding: 4px !important;
    font-weight: bold !important;
    a {
      font-weight: bold !important; } }
  a {
    color: $bodyTextColour !important; }
  #header, #middle-menu, #main-navigation, #rMenu, #companySelector, #verticalNav, .mainTabs, .subTabs, .actionPanel a, .contextMenu, a.detailedResults, .pagination, button:not(.suggestselectButton), th.checkbox, td.checkbox, .sortableHandle, .noSortableHandle, #preContent, .hidePrint, .pQp, .toggleNode, tr.mceFirst, .expand-js, #quickLinkFooter, #quickLinkCount, #pageFooter, #topBar-ui2, #leftMenu-ui2 {
    display: none !important; }
  #header1 {
    margin: 0 15px !important; }
  .lMenuContent {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    float: none !important; }
  .reportBodyScroll {
    overflow-x: visible !important; }
  .pageBreak {
    display: block;
    page-break-before: always; }
  .optionsPanel {
    display: none !important; }
  .dialogBox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin-left: 0;
    margin-top: 0;
    .lightbox {
      @include border_radius(0);
      box-shadow: none;
      position: relative;
      left: 500px;
      top: 76.7px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: block;
      border: 0;
      overflow: hidden; }
    .content.iframe {
      width: 1000px;
      height: 100%;
      margin: 0 auto; }
    > .lightbox {
      > .footer, > .header {
        display: none; } } }

  .legendText {
    visibility: hidden; }
  .zmrcntr {
    display: none; }
  .TreeContainerClass {
    height: auto!important;
    border: 0!important; }
  .tree li .trCustomBox,
  .tree li .assetHeader {
    overflow: visible;
    white-space: normal;
    word-wrap: break-word; }
  .tooltip {
    display: none!important; } }

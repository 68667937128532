/*  Form element styling
 *=======================*/
// Placeholders
%text-inputs {
  @extend %border_box;
  padding: 4px 5px;
  margin: 0;
  width: 100%;
  border: 1px solid $tabContentBorderColour;
  font-family: $defaultFontFamily !important;
  font-weight: 400;
  font-size: 13px;
  height: 26px;
  -webkit-appearance: none;
  &:focus {
    background-color: $focusColour;
    border-bottom: 1px solid #0e527f !important; } }


.labelLike {
  margin-right: 10px;
  line-height: 26px; }

.inputLike {
  position: relative;
  top: 18px; }

%radios-checkboxes {
  margin: -2px 2px 0 0;
  vertical-align: middle;
  font-size: 13px; }

/*  Inputs
 *=======================*/
object, input {
  outline: 0; }

.span3 input {
  &[type=text], &[type=number], &[type=password], &[type=search] {
    &.hasSideText {
      width: 90%; } } }
input {
  &[type=text], &[type=number], &[type=password], &[type=search], &[type=email], &[type=url] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 3px;
    @extend %text-inputs;
    &.hasSideText {
      width: 92%;
      float: left; } }
  &[type=radio], &[type=checkbox] {
    @extend %radios-checkboxes; }
  &[type=number], &.number {
    text-align: right; }
  &[type=search] {
    &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
      display: none; }
    &.datePicker, &.dateRangePicker, &.timePicker {
      width: 70px;
      height: 16px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; } }
  &[type=file] {
    @extend %border_box;
    margin: 0;
    width: 100%;
    font-family: $defaultFontFamily;
    font-size: 12px;
    margin-top: -5px; } }

input {
  &[type=color] {
    border-radius: 3px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 1px solid $tabContentBorderColour;
    height: 26px; } }

textarea {
  @extend %text-inputs;
  min-height: 92px;
  resize: vertical;
  &.match-editor {
    min-height: 151px; }
  &.htmlEditor {
    min-height: 257px; }
  &#to {
    min-height: 41px; }
  &:focus {
    background-color: $focusColour; } }

// :not to stop IE rendering grey background behind radio buttons, only affects IE, all other browsers aren't affected by backgrounds on radio.
.readonly {
  &:not([type="radio"]), &:not(.readLightGrayBackground) {
    background: #e6e6e6; }
  &.readLightGrayBackground {
    background: #F7F8F8; } }

input::-ms-clear {
  display: none; }

//webkit, safari override
input.error:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f8b5bc inset; }

#livePaymentContainer {
  .expiry input[type=text] {
    width: 50px; }
  .slash {
    font-size: 1.6em; } }



/*  Grouped inputs
 *=======================*/
.groupedInput, .transparentGroupedInput {
  border-radius: 0;
  input {
    border-radius: 0; }
  *:first-child {
    border-radius: 3px 0 0 3px; }
  *:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  @extend %border_box;
  position: relative;
  display: table;
  width: 100%;
  > input, > textarea {
    @extend %border_box;
    display: table-cell;
    float: left;
    &.buttonPadding {
      padding-right: 32px; } }
  > button:not(.suggestselectButton), > span {
    border-radius: 3px;
    display: table-cell;
    position: absolute;
    right: 0;
    height: 100%;
    margin: 0; }
  > span, >label {
    @extend %border_box;
    width: 1%;
    background: #ccc;
    padding: 0 10px;
    line-height: 0;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    white-space: nowrap;
    &.fieldHeader {
      background: none; }
    &:first-child {
      border-radius: 3px 0 0 3px;
      &.fieldHeader {
        padding-left: 0; }
      > input {
        margin: 0;
        vertical-align: middle;
        border: 1px solid red; } }
    &:last-child {
      border-radius: 0 3px 3px 0;
      &.fixedwidth {
        min-width: 80px;
        text-align: right; } } } }

.groupedInput label {
  line-height: normal; }

.transparentGroupedInput {
  > span {
    background: transparent !important; } }

.tblGrid {
  .transparentGroupedInput {
    > span {
      &:first-child {
        padding-right: 4px;
        padding-left: 0; }
      &:last-child {
        padding-right: 0;
        padding-left: 4px; } }
    input + span {
      padding-right: 0;
      padding-left: 4px; } } }

.validGroupedInput {
  position: relative;
  [class*="icon"], .valid, .invalid {
    position: absolute;
    right: 10px;
    top: 7px; } }

/*  Labels and fieldHeaders
 *=======================*/
label {
  margin-right: 10px;
  line-height: 28px;
  cursor: pointer;
  + label {
    white-space: nowrap; } }

.fieldHeader {
  display: block;
  margin: 15px 0 8px 0;
  font-weight: 700;
  color: #4c4c4c;
  line-height: inherit;
  &:has(.toggleSmall) {
    margin: 13px 0 8px 0; }
  a {
    font-weight: normal; }
  + span {
    min-height: 24px;
    display: inline-block; }
  > button, label, .groupedInput {
    margin-top: 23px; } }
.noFieldHeader {
  &:before {
    @extend .fieldHeader;
    content: "\00a0"; } }

/* QA Icon sizes */

.newFormsFormIcon {
  display: inline-block;
  margin-top: 4px;
  font-size: 22px !important;
  color: $simproBlue; }

/*  Autocompletes / Select boxes
 *=======================*/
select {
  width: 100%;
  height: 26px;
  border: 1px solid #CCCCCC !important;
  color: #262626 !important;
  font-family: $defaultFontFamily !important;
  font-weight: 400;
  font-size: 13px;
  &.scripts {
    width: 150px; } }

// Suggest select
.suggestselectButton {
  border-radius: 3px;
  position: relative;
  padding: 2px 18px 3px 5px;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 26px;
  border: 1px solid #CCCCCC !important;
  background: #fff;
  color: #262626 !important;
  font-family: $defaultFontFamily !important;
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  vertical-align: baseline;
  &:disabled {
    cursor: default;
    background: #DFDFDF; }
  >.label {
    white-space: nowrap; }
  >.subLabel {
    color: #a6a6a6; }
  span.ui-icon {
    position: absolute;
    top: 50%;
    right: 2px;
    margin-top: -8px; }
  .category {
    font-weight: bold;
    padding: 4px; }
  .selectAllPanel, .filterPanel {
    padding: 6px;
    position: relative; }
  .selectAllPanel {
    min-width: 200px;
    a {
      text-decoration: none; }
    span {
      &.ui-icon {
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -8px; }
      &.label {
        margin-left: 20px;
        margin-right: 15px; } } }
  .filterPanel input {
    width: 60%;
    margin-left: 2px; }
  .option {
    position: relative;
    .subLabel {
      display: block;
      color: #AAAAAA; } }
  .checkbox {
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    margin-top: -6px; }
  &.multiple .label {
    margin-left: 20px; }
  &:focus {
    background: #E4F2FF !important; }
  span {
    &.ui-icon {
      right: 5px !important;
      margin-top: -5px !important; }
    &.text {
      margin-left: -3px; } } }

// suggestselectResults
.suggestselectResults:not(.autocomplete) {
  max-height: 255px; }

.suggestselectResults .option .subLabel, .ui-autocomplete-description, .ui-autocomplete-extended {
  color: #a6a6a6 !important;
  display: block; }

.suggestselectResults {
  z-index: $zIndex_suggestSelectResults;
  border: 1px solid #ccc !important;
  .filterPanel {
    position: -webkit-sticky; // Safari
    position: sticky;
    top: 0px;
    background: #EBEBEB;
    padding: 5px;
    label:not(:last-child) {
      margin-right: 5px; } }
  .selectAllPanel {
    background: #EBEBEB;
    border-top: 1px solid #d9d9d9;
    a {
      display: inline-block; }
    span.ui-icon {
      margin: 3px 2px 5px 7px;
      float: left;
      + span.label {
        display: inline-block;
        margin: 4px 5px 4px 0; } } }
  .checkbox {
    margin-left: 5px!important; }
  .categoryLeft {
    background: #EBEBEB;
    overflow: hidden !important;
    padding: 8px 10px !important; }
  .optionRight {
    clear: none !important;
    padding: 5px !important;
    border-bottom: 1px solid #eee; }
  li {
    &.category:last-child {
      padding: 5px 10px!important;
      box-sizing: border-box;
      background: #EBEBEB; } }
  &#globalSearchResults {
    border-radius: 3px;
    max-height: 600px;
    min-width: 550px!important;
    overflow-x: hidden;
    z-index: 1000;
    padding: 0;
    @include media(overlaptop) {
      min-width: 250px!important; }
    .filterPanel {
      background: transparent !important;
      border: 0 !important;
      position: static; }
    li {
      a {
        padding: 0 .4em;
        .label {
          .subLabel {
            display: block; } } }
      &:nth-last-child(2) {
        a {
          &.ui-state-hover {
            margin: -1px !important; } } }
      &.category:last-child {
        padding: 5px 10px !important;
        box-sizing: border-box;
        background: #eee; } } }
  &:not(#globalSearchResults) {
    .ui-menu-item {
      font-size: 1em; }
    .ui-state-active {
      border: 0 !important;
      background: transparent !important;
      margin-left: 0 !important;
      a {
        background: #E4F2FF; }
      &:hover {
        border: 0 !important;
        a {
          border: 0 !important;
          &.ui-state-hover {
            border: 1px solid white !important; } } } } }
  &:not(.autoComplete) {
    .category {
      font-weight: 500;
      padding-left: 3px; } } }

// Magic Suggest
.ms-ctn {
  box-shadow: none;
  border-radius: 3px !important;
  font-size: 12px !important;
  input[type=text] {
    margin: 0 0 1px 1px !important;
    padding: 4px 7px !important;
    height: 23px !important;
    border: 0;
    &:focus {
      background-color: #E4F2FF !important; } }
  .ms-sel-ctn {
    background: $lightestGrey;
    .ms-sel-item {
      margin-top: 2px !important;
      margin-bottom: 2px !important;
      color: #333333 !important;
      background: none repeat scroll 0 0 #E4E4E4 !important;
      white-space: normal;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis; } } }
.ms-trigger-ico {
  height: 8px !important;
  width: 7px !important;
  border: 0 !important;
  margin-top: 1px !important;
  &:before {
    @extend .icon-down-arrow:before;
    color: #999;
    font-family: 'v12-icons';
    font-size: 0.5em; } }

.ms-ctn-bootstrap-focus {
  border-color: rgba(82, 0, 0, 0.8) !important;
  .ms-res-ctn {
    border-color: rgba(82, 0, 0, 0.8) !important; } }

.ms-res-ctn {
  z-index: 997;
  .ms-res-item-active {
    background: #bfdaff !important;
    color: #2d2525 !important; } }


/*  Misc
 *=======================*/

h2 + button {
  margin-top: 20px; }

.notes {
  margin-top: 10px; }

.auditText {
  min-width: 40px;
  max-width: 300px; }

#claimPerItemAll {
  min-width: inherit; }

.filterPanel input {
  background-color: #F7F8F9; }

.sInput {
  width: 60%;
  padding: 2px 4px 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-box-sizing: border-box;
  height: 40px;
  font-weight: normal; }


#content {
  .sInput, .btnSearch {
    height: 26px;
    color: $bodyTextColour;
    float: left;
    right: -5px; }
  table {
    .btnSearch {
      width: 40px;
      height: 20px; } }
  button + button {
    margin-left: 10px; } }


#sPanel .ui-multiselect {
  height: 40px; }


/* inner box*/
.innerBox {
  .fieldHeader {
    color: #5A5A5A; }
  .title {
    font-size: 24px; }
  #logo.login {
    margin: 30px 0 0 0; }
  .btnLogin p {
    font-size: 12px; }
  .btnLogin {
    height: 26px;
    font-size: 14px;
    background: $lightBlue;
    color: #fff;
    border: 0;
    margin-top: 38px; } }

$inpVal: (tiny: 40, sml: 65, m: 180);
@each $dir,$inpVal in $inpVal {
  .#{$dir}Input {
    width: #{$inpVal}px;
    min-width: 40px; } }
.xLInput {
  width: 250px!important; }
.lInput {
  width: 50%; }

.time {
  width: 25px;
  &:before {
    font-family: 'v12-icons';
    @extend .icon-clock:before;
    color: #a6a6a6;
    margin: 1px;
    line-height: 15px; } }


/* browse file
 *=====================*/

.browseFile {
  width: 230px;
  float: left;
  position: relative;
  margin-bottom: 5px;
  input[type=file] {
    position: absolute;
    z-index: 3;
    top: 4px;
    opacity: 0; }
  button {
    height: 24px;
    margin-left: 0;
    position: relative;
    z-index: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input {
    height: 16px;
    width: 80%;
    position: absolute;
    right: 0;
    z-index: 0;
    margin-left: -5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; } }



/*  Buttons
 *=======================*/
button {
  display: inline-block;
  height: 26px;
  margin: 0;
  padding: 0 15px;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  color: $bodyTextColour;
  background: $greyButtonBackground;
  border: 1px solid #ccc;
  font: 1em $defaultFontFamily;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 3px;
  &.btnRow:not(.saveBtn) {
    margin-right: 0;
    padding: 0 5px;
    &.blueBorder {
      border-color: $lightSimproBlue;
      color: $lightSimproBlue; } }
  &#showDetail {
    position: absolute;
    right: 10px; }
  &.text {
    padding-left: 5px;
    padding-right: 5px; }
  &.largeBtn {
    font-size: 1.5em;
    height: 45px; }
  &.btnRed {
    text-transform: uppercase;
    min-width: 80px;
    border: solid 1px $red;
    background: $red;
    color: #FFFFFF; }
  &.btnRed:hover, &.btnRed:active {
    background: #db2e2e; } }

.buttonLike {
  margin: 0;
  padding: 5px 15px;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  color: #FFF !important;
  text-align: center;
  text-decoration: none !important;
  white-space: nowrap; }

// Remove button padding in FF
button::-moz-focus-inner {
  border: 0;
  padding: 0; }

.button:hover {
  text-decoration: none;
  color: #a9a9aa; }

//  Buttons Panel
#buttonsPanel {
  right: 0;
  bottom: 4px;
  z-index: 2;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: right;
  > button, .splitButton {
    margin-left: 10px; }
  > button, .splitButton > button {
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 3px;
    height: 24px; } }

.btnGrey, .btnBlue, .btnGreen, .btnDarkblue, .btnOrange {
  min-width: 80px;
  border: 0;
  border-radius: 3px;
  text-transform: uppercase; }

button .icon-plus {
  margin-right: 3px; }

.btnSearch {
  background: #fff; }


.btnGrey {
  background: #D2D5D8;
  color: $bodyTextColour;
  &:hover:not(:disabled) {
    background: darken(#D2D5D8, 5%); }
  &:active {
    background: darken(#D2D5D8, 10%); }
  &:disabled {
    cursor: default; } }

.btnBlue {
  background: $simproBlue;
  color: #FFFFFF;
  &:hover {
    background: darken($lightBlue, 5%); }
  &:active {
    background: darken($lightBlue, 10%); } }

.btnGreen {
  background: $green;
  color: #FFFFFF;
  &:hover {
    background: darken(#40AE23, 5%); }
  &:active {
    background: darken(#40AE23, 10%); } }


.btnOrange {
  background: $lightOrange;
  color: #FFFFFF;
  &:hover {
    background: darken($lightOrange, 5%); }
  &:active {
    background: darken($lightOrange, 10%); } }

.btnDarkblue {
  background: $darkBlue;
  color: #FFFFFF;
  &:hover {
    background: darken($simproBlue, 5%); }
  &:active {
    background: darken($simproBlue, 10%); } }

// Group of buttons
.buttonsGroup {
  display: inline-flex; }

.buttonsGroup button {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  margin-left: 0!important;
  border-top: 2px solid #3B474F;
  border-bottom: 2px solid #3B474F;
  border-left: 0;
  border-right: 0;
  background: #232A2F;
  color: #FFF;
  position: relative; }

.buttonsGroup button:not(:first-child)::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -3px;
  height: calc(100% + 2px);
  width: 1px;
  background-color: #3B474F; }

.buttonsGroup button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 0;
  border-left: 2px solid #3B474F; }

.buttonsGroup button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
  border-right: 2px solid #3B474F; }

//secondary buttons
.btnSec {
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  [class*="icon-"] {
    padding-top: 1px;
    display: inline-block; }
  .btnIcon {
    vertical-align: baseline;
    margin-left: 5px; }
  &--secondaryButton {
    border-color: $lightSimproBlue;
    color: $lightSimproBlue;
    background-color: #fff;
    min-width: 100px;
    text-transform: uppercase;
    &:hover {
      background-color: $greyButtonBackground; } } }
.saveBtn {
  font-size: 12px;
  height: 26px;
  &.btnPanelSaveBtn {
    height: 24px; }
  &.rightMargin {
    margin-right: 7px; }
  &.leftMargin {
    margin-left: 7px; }
  &.topMargin {
    margin-top: 7px; }
  &.bottomMargin {
    margin-bottom: 7px; }
  .buttonIconRight {
    font-size: 0.5em;
    padding: 3px 0 0 5px;
    float: right; }
  .buttonIconLeft {
    font-size: 0.5em;
    padding: 3px 5px 0 0;
    float: left; } }

.btnRow:not(.saveBtn) {
  background: #fff;
  border: 1px solid $darkGrey;
  border-radius: 3px;
  color: $darkGrey;
  font-size: 11px;
  height: 20px !important;
  &:active {
    background: $lightestGrey; } }
.btnRow {
  span.options {
    &:after {
      @extend .icon-down-arrow:before;
      font-family: "v12-icons";
      font-size: 0.6em;
      padding-left: 5px;
      color: #666; } }
  span.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    &:before {
      content: "\2026";
      font-weight: bold;
      font-family: $defaultFontFamily; } }
  &.btnRowIsolated {
    height: 26px !important; } }

.scheduleButton {
  color: #999;
  background: $headerGrey;
  width: 42px;
  padding: 0 5px;
  line-height: 22px;
  .icon-calendar {
    font-size: 14px;
    float: left; }
  .icon-right-double-arrow {
    font-size: 8px;
    float: right;
    margin-top: 4px; } }

.dropArrow {
  &:after {
    font-family: 'v12-icons';
    @extend .icon-down-arrow:before;
    font-size: 0.6em;
    padding-left: 5px; } }

.dropArrowSelectWrapper {
  display: inline-block;
  vertical-align: text-bottom;
  border: 1px solid #fff; }

.dropArrowSelect {
  &:after {
    font-family: 'v12-icons';
    @extend .icon-down-arrow:before;
    font-size: 0.6em;
    padding-left: 5px; } }

.dropArrowSelectWrapper:hover {
  border: 1px solid #ccc;
  cursor: pointer; }

button:active:not(.suggestselectButton) {
  padding-top: 2px; }

/* descriptive button*/

.btnDesc {
  padding-left: 5px;
  padding-right: 5px; }

/* Button Icons*/
.btnImg {
  width: auto;
  height: auto;
  padding: 2px;
  vertical-align: top;
  border: 1px solid #cccccc;
  background: #fff;
  &:hover {
    border-color: #9da1a6; }
  &:active {
    background: #e7e4e4; }
  span {
    display: block;
    width: 15px;
    height: 15px;
    &.med {
      width: 20px;
      height: 20px; }
    &.large {
      width: 22px;
      height: 18px; } } }

.exportDownArrow {
  &:before {
    font-family: 'v12-icons';
    @extend .icon-download:before; } }

h2 .exportDownArrow {
  height: 1.1em;
  vertical-align: middle;
  &:before {
    font-size: 0.8em;
    position: relative;
    top: -3px; } }

.btnImg span {
  &.magnify {
    background: transparent;
    &:before {
      font-family: 'v12-icons';
      @extend .icon-search:before;
      color: #a6a6a6;
      margin: 0 4px;
      line-height: 17px;
      font-size: 15px; } }
  &.icon-location-search {
    color: #a6a6a6;
    line-height: 17px;
    font-size: 15px; }
  &.icon-leads {
    color: #a6a6a6;
    line-height: 14px;
    font-size: 15px;
    padding: 0 5px 0 3px; }
  &.icon-pin {
    color: #a6a6a6;
    line-height: 17px;
    font-size: 15px; }
  &.date {
    background: transparent;
    &:before {
      font-family: 'v12-icons';
      @extend .icon-calendar:before;
      color: #a6a6a6;
      margin: 0;
      line-height: 15px;
      font-size: 15px; } }
  &.time {
    @extend .icon-clock;
    font-family: 'v12-icons';
    color: #a6a6a6;
    line-height: 15px;
    font-size: 15px; } }

.btnToggleGridPanel {
  float: right;
  margin-top: 10px;
  margin-right: 6px; }

.datePicker, .dateRangePicker, .timePicker {
  width: 65px;
  + button {
    padding: 0 4px;
    margin-left: 0;
    border-left: none;
    border-color: #cccccc;
    background: #fff;
    border-left: 1px solid #ccc; } }

.dfAddBtn {
  @extend .right;
  margin-top: -27px;
  position: relative;
  z-index: 2;
  &.dfAddBtnCompact {
    margin-top: -25px; } }
.dfAddBtnStatus {
  top: -42px;
  margin-right: 10px; }

.transparentBtn {
  border: none;
  background: transparent;
  text-transform: uppercase;
  color: $simproBlue;
  &:hover {
    border: 1px solid $simproBlue; } }

.transparentBtnBorder {
  border: 1px solid $simproBlue;
  background: transparent;
  text-transform: uppercase;
  color: $simproBlue;
  &:hover {
    color: #fff;
    background: $simproBlue; } }

.btnTxtWrap {
  word-break: break-word;
  white-space: normal;
  padding: 5px;
  height: auto; }

@import "formElements/switchToggle";

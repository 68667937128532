#jobssearchPage .divForm #tabsPanel0 .subTabs, #InvoiceHistoryContainer #invoicessearchPage .divForm #tabsPanel0 .subTabs,
#quotessearchPage .divForm #tabsPanel0 .subTabs, #leadssearchPage .divForm #tabsPanel0 .subTabs,
#recurringsearchPage .divForm #tabsPanel0 .subTabs, #workOrdersHistorysearchPage .divForm #tabsPanel0 .subTabs,
#workOrdersInvoiceHistorysearchPage .divForm #tabsPanel0 .subTabs {
  background: $lightestGrey !important; }

#jobTabs.subTabs, #invoiceTabs.subTabs, #quoteTabs.subTabs, #leadsTabs.subTabs {
  background: #e2e2e2; }

#jobTabs, #quoteTabs, #invoiceTabs, #leadTabs {
  background: #e2e2e2 !important; }

#preContent {
  border-top-right-radius: 5px;
  background-color: #FFFFFF;
  height: 7px;
  &.corner_top_left {
    border-top-left-radius: 5px; } }

/* Main tabs */
#tabsButtonsPanel {
  position: relative; }

.mainTabs {
  border-bottom: 2px solid #e2e2e2;
  min-height: 2.7em;
  margin-bottom: -2px;
  li {
    display: inline;
    float: left;
    margin-right: 5px;
    margin-bottom: -1px;
    a, #profileForm #tabsPanel1 li a {
      background: #ECECEC;
      display: block;
      padding: 6px 23.25px 6px;
      &.current {
        z-index: 10000; }
      &.disabled {
        color: #999;
        background: #C5C9CC;
        cursor: default; }
      &.plus {
        width: 35px;
        min-width: 0;
        padding: 9px 0 8px; } } } }

/* sub tab */

.subTabs {
  padding: 0 0 0 10px;
  list-style: none;
  display: block;
  @extend %clearfix;
  li {
    display: inline;
    float: left;
    margin-left: 5px;
    margin-right: 0;
    margin-top: 0;
    &.right {
      float: right; }
    a {
      border: 1px solid transparent;
      border-bottom: 0;
      margin-bottom: -1px;
      display: block;
      padding: 5px 15px 8px 15px;
      padding-top: 8px;
      padding-bottom: 10px;
      position: relative;
      color: #fff;
      &.current {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        .currentSubtab {
          display: inline-block;
          width: 17px;
          height: 8px;
          position: absolute;
          top: -1px;
          left: 40%;
          background-position: 0 -590px; } }
      &:hover, &.current {
        padding: 5px 15px 8px 15px;
        margin-bottom: -1px;
        border-bottom: 0;
        background: #fff;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        padding-top: 8px;
        padding-bottom: 10px;
        span.desc, &.current span.desc {
          padding: 0; } } } }
  &.descriptive li a {
    padding: 8px 10px;
    span.desc {
      color: #777;
      margin: 0 5px;
      display: none; } }
  + .subTabs {
    margin-top: 5px; } }



// styling for vertical tabs (eg: used in customer duplication)

.dialogBox {
  .verticalTabs {
    margin: -20px; } }

#availabiltyTab {
  border-bottom: 2px solid $headerGrey;
  border-radius: 3px 3px 0 0;
  &.subTabs {
    padding-bottom: 0 !important; } }

.iframe #tabsPanel0 .subTabs li a.current {
  //background: $headerGrey !important
  margin-bottom: 0; }

#subSubTabs {
  background: $lightestGrey;
  li a {
    background: none; } }

.verticalTabs {
  margin: 0;
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 400px;
  nav {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    color: #262626;
    width: 150px;
    a {
      padding: 10px;
      width: 100%;
      cursor: pointer;
      color: #262626;
      &:hover, &.selected {
        background: #004B8D;
        color: #fff; } } }
  .contentTabs {
    padding: 20px 0;
    position: absolute;
    top: 0;
    left: 150px;
    width: 0;
    height: 97.5%;
    overflow: hidden;
    display: none; }
  .contentTabs.visible {
    padding: 10px 0 0 20px;
    width: 65%;
    display: block;
    border-left: 1px solid #ccc; }
  div {
    padding-bottom: 2px; }
  div:last-of-type {
    padding-bottom: 0; } }

@include tab_theme(#fff, #000, #000);
@include line_tab(#fff, $lightSimproBlue);

.tabsContent.subSubTabs .subTabs {
  background: $lightestGrey;
  li a {
    &.current, &:hover {
      background: $lightestGrey; } } }

.security-groups-tabs button.right.saveBtn {
  margin: 10px 10px 0 10px; }

/////////////////
// Semantic.gs // for SCSS: http://sass-lang.com/
/////////////////

// Defaults which you can freely override
$column-width: 60px;
$gutter-width: 20px;
$columns: 12;
$generateGrid: false !default;
// Set $total-width to 100% for a fluid layout
//$total-width: gridsystem-width($columns)
$total-width: 100%;

//this should be extended in column mixin once sass supports extended placeholders in media queries.  It will cut down a lot of repetitive code
%column-display {
  @extend %border_box;
  display: inline;
  float: left; }


// Utility function — you should never need to modify this
@function gridsystem-width($columns: $columns) {
  @return $column-width * $columns + $gutter-width * $columns; }


// Convenience function for calculating the $total-width / $calculated-width.
@function gridsystem-ratio($c: $columns) {
  @if $total-width == 100% {
    @return $total-width / gridsystem-width($c); }
  @else {
    @return $total-width / gridsystem-width($columns); } }

@function col_total_width($x, $columns: $columns) {
  $width: $total-width * (($gutter-width + $column-width) * $x - $gutter-width) / gridsystem-width($columns);
  $margin: $total-width * $gutter-width * 0.5 / gridsystem-width($columns) * 2;
  @return $width + $margin; }

@function calc_col_margin($x, $columns: $columns) {
  $margin: $total-width * $gutter-width * 0.5 / gridsystem-width($columns);
  @return $margin; }

@mixin row($columns: $columns) {
  box-sizing: border-box;
  display: block;
  width: $total-width * ($gutter-width + gridsystem-width($columns)) / gridsystem-width($columns);
  margin: 0 $total-width * $gutter-width * 0.5 / gridsystem-width($columns) * -1; }

@mixin column($x, $columns: $columns) {
  display: inline;
  float: left;
  width: $total-width * (($gutter-width + $column-width) * $x - $gutter-width) / gridsystem-width($columns);
  margin: 0 $total-width * $gutter-width * 0.5 / gridsystem-width($columns); }

@mixin push($offset: 1) {
  margin-left: $total-width * ($gutter-width + $column-width) * $offset / gridsystem-width($columns) + $total-width * $gutter-width * 0.5 / gridsystem-width($columns); }

@mixin pull($offset: 1) {
  margin-right: $total-width * ($gutter-width + $column-width) * $offset / gridsystem-width($columns) + $total-width * $gutter-width * 0.5 / gridsystem-width($columns); }

@if $generateGrid {
  @for $i from 1 through $columns {
    .span#{$i} {
      @include column($i);
      box-sizing: border-box;
      min-height: 1px; } }

  @for $i from 1 through $columns {
    .push#{$i} {
      @include push($i); } }
  @for $i from 1 through $columns {
    .pull#{$i} {
      @include pull($i); } }

  .row {
    @include row(12);
    @extend %clearfix;
    &.blockRow {
      padding-top: 10px;
      padding-bottom: 10px; } } }

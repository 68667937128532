@import "mixins";
.quickLinkFooterClick {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-right: 40px;
  height: 40px;
  width: 100%;
  background: linear-gradient(to bottom,$simproBlue 0%, $simproBlue 75%, darken($simproBlue, 10) 76%, darken($simproBlue, 10) 80%,  darken($simproBlue, 8) 81%,  darken($simproBlue, 8) 100%);
  box-sizing: border-box;
  z-index: 100;
  li {
    width: col_total_width(12/5);
    float: left;
    padding: 5px 30px 7px;
    box-sizing: border-box;
    cursor: pointer;
    color: #F7F8F9;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .statusIcons {
      width: initial;
      padding-left: 5px;
      flex-warp: wrap; }
    .span10 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    [class^=icon] {
      margin-right: 5px;
      vertical-align: -11%;
      font-size: 1.2em; }
    &:last-child {
      position: absolute;
      width: 35px;
      height: 30px;
      right: 0px;
      background-color: $simproBlue;
      text-overflow: clip;
      span {
        position: relative;
        right: 7px;
        top: 2px;
        font-size: 10px; } }
    &.active {
      color: #fff;
      cursor: default;
      font-weight: 500; }
    &:hover:not(.active) {
      background-color: $darkBlue;
      color: #fff; } } }

.quickLinkFooterHover {
  float: right;
  bottom: 30px;
  position: fixed;
  background-color: $simproBlue;
  width: 20%;
  right: 0;
  color: white;
  z-index: 1000;
  li {
    padding: 5px;
    color: #f5f5f5;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid #A4A5A6;
    .statusIcons {
      margin-top: 8px;
      width: initial;
      padding-left: 5px;
      flex-warp: wrap; }
    [class^=icon] {
      font-size: 1.2em; }
    &.active {
      color: #fff;
      font-weight: 500;
      cursor: default; }
    &:hover:not(.active) {
      background-color: $darkBlue;
      color: #fff; }
    .projectDesc {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; } } }

#quickLinkCount {
  position: fixed;
  bottom: 0;
  right: 0px;
  padding: 6px 10px 6px 10px;
  background-color: $simproBlue;
  color: #f5f5f5;
  cursor: pointer;
  z-index: 100;
  span {
    margin-left: 5px;
    font-size: 10px; }
  &:hover {
    color: #fff; } }

.companyColour {
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 5px; }

#content_container.jobClosurePage, #content_container.jobClosurePage #content, .transparent, #content_container.closurePage, #content_container.closurePage #content {
  background-color: transparent !important;
  border: 0 !important; }

#jobClosure {
  h2 {
    font-size: 21px;
    font-weight: 300;
    margin-bottom: 20px; }
  ul.closureList {
    width: 400px;
    margin: 10px auto;
    padding: 0px;
    background: #fff;
    border: 1px solid $tabContentBorderColour;
    li {
      font-size: 1.25em;
      label {
        padding: 10px 20px;
        cursor: pointer;
        margin: 0;
        display: block; }
      input[type=checkbox] {
        margin: 0 10px 0 0;
        width: 18px;
        height: 18px;
        vertical-align: text-top; }
      &:not(:first-child) {
        border-top: 1px solid $tabContentBorderColour; }
      &:hover {
        color: #fff;
        background: $lightBlue;
        .icon-minus {
          color: #fff; } } }
    small + small {
      font-size: .7em;
      display: block; } }
  .icon-minus {
    margin-top: -9px; }
  .companyColour {
    width: 16px;
    height: 16px;
    vertical-align: text-top; }
  ul.actionLinks {
    width: 409px;
    margin: 20px auto;
    line-height: 26px;
    font-size: 12px;
    button {
      font-size: 12px; }
    li {
      display: inline-block;
      label {
        padding: 10px 10px 10px 0;
        cursor: pointer;
        margin: 0; }
      input[type=checkbox] {
        margin: 0 5px;
        width: 18px;
        height: 18px; } } } }

#finishState {
  margin: 2em auto;
  width: 600px;
  border: 1px solid #ccc!important;
  font-size: 13px;
  padding: 0;
  h2 {
    font-weight: normal;
    color: #4c4c4c; }
  a {
    color: #4c4c4c;
    &:hover {
      color: #004B8D;
      .round-button {
        background: #004B8D; } } }
  .horizontalPadding {
    padding: 0 20px; }
  .verticalPadding {
    padding: 20px 0; }
  .totalPadding {
    padding: 20px; }
  .greyBackground {
    background: #F7F8F8; }
  .inlineDisplay {
    display: inline-block; }
  button {
    background: white!important;
    margin-bottom : 10px; } }

.round-legend, .round-button {
  display: block;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  text-decoration: none;
  padding: 10px; }

.round-legend {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #40AE23;
  font-size: 24px; }

.round-button {
  width: 25px;
  height: 25px;
  line-height: 25px;
  background: #00aeef;
  font-size: 18px;
  margin: 10px auto;
  border-bottom: 2px solid #ccc; }

.rotateIcon {
  -ms-transform: rotate(90deg) /* IE 9 */;
  -webkit-transform: rotate(90deg) /* Chrome, Safari, Opera */;
  transform: rotate(90deg); }

/*styles for jquery ui daterangepicker plugin */

.ui-daterangepickercontain {
	position: absolute;
	z-index: 999;
}
.ui-daterangepickercontain .ui-daterangepicker {
	float: left;
	padding: 5px !important;
	width: auto;
	display: inline;
	background-image: none !important;
	clear: left;
}
.ui-daterangepicker ul, .ui-daterangepicker .ranges, .ui-daterangepicker .range-start, .ui-daterangepicker .range-end {
	float: left;
	padding: 0;
	margin: 0;
}
.ui-daterangepicker .ranges {
	width: auto;
	position: relative;
	padding: 5px 5px 40px 0;
	margin-left: 10px;
}
.ui-daterangepicker .range-start, .ui-daterangepicker .range-end {
	margin-left: 5px;
}
.ui-daterangepicker button.btnDone {
	margin: 0 5px 5px 0;
	position: absolute;
	bottom: 0;
	right: 0;
	clear: both;
	cursor: pointer;
	font-size: 1.1em;
}
.ui-daterangepicker ul {
	width: 17.6em;
	background: none;
	border: 0;
}
.ui-daterangepicker li {
	list-style: none;
	padding: 1px;
	cursor: pointer;
	margin: 1px 0;
}
.ui-daterangepicker li.ui-state-hover, .ui-daterangepicker li.ui-state-active {
	padding: 0;
}
.ui-daterangepicker li.preset_0 {
	margin-top: 1.5em !important;
}
.ui-daterangepicker .ui-widget-content a {
	text-decoration: none !important;
}
.ui-daterangepicker li a {
	font-weight: normal;
	margin: .3em .5em;
	display: block;
}
.ui-daterangepicker li span {
	float: right;
	margin: .3em .2em;
}
.ui-daterangepicker .title-start, .ui-daterangepicker .title-end {
	display: block;
	margin: 0 0 .2em;
	font-size: 1em;
	padding: 0 4px 2px;
}
.ui-daterangepicker .ui-datepicker-inline {
	font-size: 1em;
}
.ui-daterangepicker-arrows {
	padding: 2px;
	width: 204px;
	position: relative;
}
.ui-daterangepicker-arrows input.ui-rangepicker-input {
	width: 158px;
	margin: 0 2px 0 20px;
	padding: 2px;
	height: 1.1em;
}
.ui-daterangepicker-arrows .ui-daterangepicker-prev, .ui-daterangepicker-arrows .ui-daterangepicker-next {
	position: absolute;
	top: 2px;
	padding: 1px;
}
.ui-daterangepicker-arrows .ui-daterangepicker-prev {
	left: 2px;
}
.ui-daterangepicker-arrows .ui-daterangepicker-next {
	right: 2px;
}
.ui-daterangepicker-arrows .ui-daterangepicker-prev:hover,
.ui-daterangepicker-arrows .ui-daterangepicker-next:hover,
.ui-daterangepicker-arrows .ui-daterangepicker-prev:focus,
.ui-daterangepicker-arrows .ui-daterangepicker-next:focus {
	padding: 0;
}

.icon-leads:before { content: "\e001" };
.icon-people:before { content: "\e002" };
.icon-jobs:before { content: "\e004" };
.icon-calendar:before { content: "\e005" };
.icon-materials:before { content: "\e006" };
.icon-sales:before { content: "\e007" };
.icon-help:before { content: "\e008" };
.icon-doc:before { content: "\e009" };
.icon-reports:before { content: "\e00a" };
.icon-utilities:before { content: "\e00b" };
.icon-settings:before { content: "\e00c" };
.icon-down-arrow:before { content: "\e00e" };
.icon-left-arrow:before { content: "\e00f" };
.icon-right-arrow:before { content: "\e010" };
.icon-up-arrow:before { content: "\e011" };
.icon-plus:before { content: "\e012" };
.icon-minus:before { content: "\e013" };
.icon-tick:before { content: "\e014" };
.icon-logout:before { content: "\e015" };
.icon-person:before { content: "\e016" };
.icon-rotate-left:before { content: "\e050" };
.icon-rotate-right:before { content: "\e051" };
.icon-dashboard:before { content: "\e600" };
.icon-refresh:before { content: "\e601" };
.icon-exclamation:before { content: "\e603" };
.icon-profit:before { content: "\e606" };
.icon-edit:before { content: "\e607" };
.icon-print:before { content: "\e608" };
.icon-tasks:before { content: "\e609" };
.icon-quotes:before { content: "\e60a" };
.icon-alert:before { content: "\e60b" };
.icon-bookmark:before { content: "\e60c" };
.icon-question:before { content: "\e60d" };
.icon-move:before { content: "\e60e" };
.icon-unlock:before { content: "\e60f" };
.icon-lock:before { content: "\e610" };
.icon-torx:before { content: "\e611" };
.icon-download:before { content: "\e612" };
.icon-location-search:before { content: "\e613" };
.icon-pin:before { content: "\e614" };
.icon-clock:before { content: "\e615" };
.icon-shrink:before { content: "\e616" };
.icon-expand:before { content: "\e617" };
.icon-connect-logo:before { content: "\e618" };
.icon-file:before { content: "\e619" };
.icon-credit-card:before { content: "\e61a" };
.icon-copy:before { content: "\e61b" };
.icon-star:before { content: "\e61c" };
.icon-hammer:before { content: "\e61d" };
.icon-phone:before { content: "\e61e" };
.icon-mail:before { content: "\e61f" };
.icon-invoices:before { content: "\e620" };
.icon-list:before { content: "\e621" };
.icon-grid:before { content: "\e900" };
.icon-text-color:before { content: "\e901" };
.icon-redo:before { content: "\e902" };
.icon-undo:before { content: "\e903" };
.icon-strikethrough:before { content: "\e904" };
.icon-italic:before { content: "\e905" };
.icon-underline:before { content: "\e906" };
.icon-bold:before { content: "\e907" };
.icon-h-square:before { content: "\e908" };
.icon-folder-open:before { content: "\e909" };
.icon-folder:before { content: "\e90a" };
.icon-attachment:before { content: "\e90b" };
.icon-image:before { content: "\e90c" };
.icon-link:before { content: "\e90d" };
.icon-payments-logo:before { content: "\e90e" };
.icon-secure:before { content: "\e90f" };
.icon-payments-icon:before { content: "\e910" };
.icon-find:before { content: "\e911" };
.icon-angle-double-right:before { content: "\e912" };
.icon-angle-double-left:before { content: "\e913" };
.icon-info-circle:before { content: "\e914" };
.icon-unlink:before { content: "\e915" };
.icon-outdent:before { content: "\e916" };
.icon-indent:before { content: "\e917" };
.icon-numlist:before { content: "\e918" };
.icon-bullist:before { content: "\e919" };
.icon-table:before { content: "\e91a" };
.icon-enlarge:before { content: "\e91b" };
.icon-paragraph-justify:before { content: "\e91c" };
.icon-paragraph-center:before { content: "\e91d" };
.icon-paragraph-left:before { content: "\e91e" };
.icon-paragraph-right:before { content: "\e91f" };
.icon-more:before { content: "\e920" };
.icon-price-tag:before { content: "\e921" };
.icon-cancel:before { content: "\e922" };
.icon-see:before { content: "\e923" };
.icon-addFolder:before { content: "\e924" };
.icon-continue:before { content: "\e925" };
.icon-send:before { content: "\e926" };
.icon-tree:before { content: "\e927" };
.icon-chevron-down:before { content: "\e928" };
.icon-chevron-up:before { content: "\e929" };
.icon-launch:before { content: "\e92a" };
.icon-signature-accept:before { content: "\e92b" };
.icon-expired:before { content: "\e92c" };
.icon-repeat:before { content: "\e92d" };
.icon-people-outline:before { content: "\e940" };
.icon-quotes-outline:before { content: "\e941" };
.icon-jobs-outline:before { content: "\e942" };
.icon-calendar-outline:before { content: "\e943" };
.icon-materials-outline:before { content: "\e944" };
.icon-invoices-outline:before { content: "\e945" };
.icon-tasks-outline:before { content: "\e946" };
.icon-doc-outline:before { content: "\e947" };
.icon-utilities-outline:before { content: "\e948" };
.icon-reports-outline:before { content: "\e949" };
.icon-leads-outline:before { content: "\e94a" };
.icon-help-outline:before { content: "\e94b" };
.icon-payrix-logo:before { content: "\ea01" };
.icon-fullscreen-expand:before { content: "\ea05" };
.icon-fullscreen-collapse:before { content: "\ea06" };
.icon-trash:before { content: "\ea08" };
.icon-plus-circle-outline:before { content: "\ea50" };
.icon-minus-circle-outline:before { content: "\ea51" };
.icon-round-alert:before { content: "\ef01" };
.icon-subeez-chevron-down:before { content: "\ef02" };
.icon-subeez-arrow-left:before { content: "\ef03" };
.icon-subeez-arrow-right:before { content: "\ef04" };
.icon-subeez-cross:before { content: "\ef05" };
.icon-subeez-check:before { content: "\ef06" };
.icon-search:before { content: "\f002" };
.icon-push-pin:before { content: "\f003" };
.icon-multi-company:before { content: "\f004" };
.icon-outline-alert:before { content: "\f005" };
.icon-filled-alert:before { content: "\f006" };
.icon-bookmark-outline:before { content: "\f007" };
.icon-user:before { content: "\f008" };
.icon-headset:before { content: "\f009" };
.icon-telephone:before { content: "\f010" };
.icon-check-alt:before { content: "\f011" };
.icon-camera:before { content: "\f012" };
.icon-pick-up:before { content: "\f013" };
.icon-delivery:before { content: "\f014" };
.icon-secure-payment:before { content: "\f015" };
.icon-notification:before { content: "\f016" };
.icon-announcement:before { content: "\f017" };

@font-face {
  font-family: 'v12-icons';
  src: url('fonts/v12-icons.eot');
  src: url('fonts/v12-icons.eot') format("embedded-opentype"), url('fonts/v12-icons.woff') format("woff"), url('fonts/v12-icons.svg') format("svg"), url('fonts/v12-icons.ttf') format("truetype");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'v12-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering ===========*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

//call centre
[data-label="Dashboard"] > span:before, [data-label="Operations Centre"] > span:before {
  @extend [class^="icon-"];
  color: $simproBlue;
  font-size: 16px;
  width: 20px;
  vertical-align: -10%;
  display: inline-block; }

.icon-torx:before {
  font-size: 1.1em; }

[data-label="Dashboard"] > span:before { //call centre
  content: unicode("e600"); }

[data-label="Operations Centre"] > span:before { //call centre
  content: unicode("e00b"); }

.icon-left-double-arrow:before {
  content: unicode2("e00f","e00f");
  letter-spacing: -0.1em; }

.icon-right-double-arrow:before {
  content: unicode2("e010","e010");
  letter-spacing: -0.1em; }

@mixin arrowSpacing {
  white-space: pre;
  float: left;
  padding: 3px 5px 0 0; }

.icon-up-double-arrow:before {
  content: unicode3("e011","A","e011");
  @include arrowSpacing; }

.icon-down-double-arrow:before {
  content: unicode3("e00e","A","e00e");
  @include arrowSpacing; }

.icon-first:before {
  content: unicode2("e00f","e00f");
  border-left-width: 1px;
  border-left-style: solid;
  letter-spacing: -0.1em; }

.icon-last:before {
  content: unicode2("e010","e010");
  border-right-width: 1px;
  border-right-style: solid;
  letter-spacing: -0.1em;
  padding-right: 1px; }

.icon-connect-logo:before {
  font-size: 1.2em; }

.icon-expand-mce:before {
  content: unicode("e91b"); }

.mce-i-expand:before {
  font-family: 'v12-icons';
  content: unicode("e91b"); }

.mce-i-collapse:before {
  font-family: 'v12-icons';
  content: unicode("e616"); }

.mce-i-more:before {
  font-family: 'v12-icons';
  content: unicode("e920"); }

.icon-payments-logo:before,
.icon-payrix-logo:before {
  font-size: 2em; }

.icon-angle-double-right:before {
  font-size: 2em; }

.icon-angle-double-left:before {
  font-size: 2em; }

.icon-profit:before {
  font-size: 1.2em!important; }

.icon-info-circle:before {
  font-size: 1.6em!important; }

.icon-info-circle-simpro-blue {
  @extend .icon-info-circle;
  color: $simproBlue; }

.icon-send:before {
  font-size: 1.2em; }

.icon-close:before {
  content: unicode("e922"); }

@import 'fonts/v12-icons';

//  Global variables, mixins, functions, placeholders
$simproBlue: #1976D2;
$lightSimproBlue: #229BFF;
$lightBlue: $lightSimproBlue;
$lighterBlue: #91bce7;
$midBlue: #1568b1;
$darkBlue: #0E527F;
$headerGrey: #F0F1F2;
$green: #41B555;
$lightOrange: #FAAF3C;
$red: #DE104F;
$lightestGrey: #F7F8F9;
$midDarkGrey: #e2e2e2;
$darkGrey: #596065;
$yellow: #feed78;
$orange: #FF7740;
$bodyTextColour: #232A2F;
$headerText: #262626;
$thBackground: #fff;
$dropdownBackground: $thBackground;
$trAltColour: #E4F2FF;
$bodyBackground: #fff;
$tabContentBorderColour: #ccc;
$utilityMenuFontSize: 12px;
$focusColour: #DDEFFC;
$headerMenuWidth: 158px;
$pagePadding: 28px;
$pagePadding_UI2: 14px;
$topBarHeight_UI2: 40px;
$leftMenuCollapsedWidth_UI2: 50px;
$leftMenuExpandedWidth_UI2: 170px;
$leftMenuSubmenuWidth_UI2: 250px;
$expandedLogoHeight_UI2: 30px;
$editPanelBg: lighten($focusColour,5%);
$greyButtonBackground: #e7e9ec;
$defaultFontFamily: "Roboto", sans-serif;
$scheduleBorderColour: #e1e1e1;
$greyShade4: #82888c;
$border: #f3f3f3;
$pdfCanvasWidth: 775px;

$simproPaymentsFont: "Cocogoose Pro";
$simproPaymentsFontFamily: "Cocogoose Pro", sans-serif;
$simproPaymentsFontSrcUrl: "fonts/cocogoose-pro-variable/Cocogoose-Pro-Variable.woff2";
$simproPaymentsFontSrcFormat: "woff2";
$simproPaymentsBlue: #002F87;
$simproPaymentsYellow: #FFDD00;
$simproPaymentsMarketingBodyColor: #4A4A4A;
$simproPaymentsHeaderBlue: #0A1626;
$simproPaymentsFooterBlue: #0A2240;

$zIndex_stickyHeader: 500;
$zIndex_suggestSelectResults: 600;
$zIndex_topBar: 997;
$zIndex_leftMenu: 998;
$zIndex_tooltip: $zIndex_leftMenu;
$zIndex_dialogBox: 999;
$zIndex_fileUploadContainer: 1000;
$zIndex_overDialogBox: $zIndex_fileUploadContainer;
$zIndex_uiDatePicker: $zIndex_fileUploadContainer;
$zIndex_dialogBoxTooltip: 1001;
$zIndex_loadingItems: 1100;
$zIndex_loadingImage: 99999;


// Mixins
@mixin tab_theme($color, $activeTabTextColour, $textColour) {
  .mainTabs {
    li {
      a, #profileForm #tabsPanel1 li a {
        background: #fff;
        color: $simproBlue;
        border-radius: 3px 3px 0 0;
        border: 1px solid #fff;
        border-left: 0;
        &:hover {
          background: $lightestGrey;
          color: $simproBlue;
          border-bottom: 1px solid $lightestGrey; }
        &.current {
          border-bottom: 1px solid #e2e2e2;
          background: #e2e2e2;
          font-weight: bold;
          color: #000; }
        &.current:hover {
          color: #000; } } } } }


@mixin line_tab($color, $textColour) {
  #preContent {
    background-color: $color; }
  .subTabs {
    background: #e2e2e2;
    padding-top: 7px;
    padding-bottom: 5px;
    li {
      margin-right: 20px;
      a {
        color: $simproBlue;
        border-width: 0;
        &:hover {
          font-weight: normal;
          background-color: #e2e2e2;
          color: #000; }
        &.current {
          background-color: #e2e2e2;
          color: #000;
          font-weight: bold; }
        &.disabled {
          color: #888; } } } }

  .iframe #tabsPanel0 .subTabs {
    margin-top: 0;
    li {
      a {
        padding: 10px 15px 5px 15px;
        &:hover,&.current {
          background: transparent; } } }
 } }    //background: lighten($color, 30%)

@mixin bubble($size, $color) {
  min-width: 15px;
  height: $size;
  border-radius: $size;
  line-height: $size+1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  background-color: $color;
  display: inline-block;
  color: white;
  padding: 0 5px; }

@mixin border_radius($size: 3px) {
  border-radius: $size; }

@mixin prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument}; }

@mixin coloured_heading($bgcolor: $lightBlue, $corner: #fff, $color: #fff, $padding: 5px 20px) {
  float: left;
  position: relative;
  background-color: $bgcolor;
  padding: $padding;
  color: $color;
  font-weight: 400;
  &:before {
    position: absolute;
    right: -1px;
    bottom: -1px;
    content: '';
    border-bottom: 1em solid $corner;
    border-left: 1em solid transparent;
    background-repeat: no-repeat; } }

@mixin simpro_payment_coloured_heading($bgcolor: $simproPaymentsBlue, $corner: #fff, $color: $simproPaymentsYellow, $padding: 7px 80px 3px 15px) {
  float: left;
  position: relative;
  background-color: $bgcolor;
  padding: $padding;
  color: $color;
  font-weight: 400;
  &:before {
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    border-top: 2em solid $corner;
    border-left: 2em solid transparent;
    background-repeat: no-repeat; } }

@mixin media($type) {
  @if $type == phone {
    @media only screen and (max-width: 480px) {
      @content; } }
  @else if $type == tablet {
    @media only screen and (max-width: 768px) {
      @content; } }
  @else if $type == netbook {
    @media only screen and (min-width: 1200px) and (max-height: 768px) {
      @content; } }
  @else if $type == laptop {
    @media only screen and (min-width: 1280px) and (max-width: 1366px) {
      @content; } }
  @else if $type == overlaptop {
    @media only screen and (max-width: 1366px) {
      @content; } }
  @else if $type == smallest {
    @media only screen and (max-width: 1280px) {
      @content; } }
  @else if $type == oversmallest {
    @media only screen and (min-width: 1280px) {
      @content; } }
  @else if $type == large {
    @media only screen and (max-width: 1680px) {
      @content; } } }


@mixin diagonal_background($firstColour, $secondColour: #fff, $angle: 45deg, $sizes: (4px, 2px, 7px)) {
  background: repeating-linear-gradient($angle,$firstColour,$firstColour nth($sizes,1),$secondColour nth($sizes,2),$secondColour nth($sizes,3)); }

// Placeholders
%border_box {
  box-sizing: border-box; }

%box_shadow {
  box-shadow: 0 3px 3px #ddd; }

%clearfix {
  zoom: 1;
  &:before, &:after {
    content: "";
    display: table; }
  &:after {
    clear: both; } }

/* These functions are needed to produce proper unicode output in non-compressed compiled sass files. Accepts unescaped unicode character/s eg. 6301 */
@function unicode($str) {
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\""); }

@function unicode2($str, $str2) {
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote(str-insert($str2, "\\", 1))+unquote("\""); }

@function unicode3($str, $str2, $str3) {
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote(str-insert($str2, "\\", 1))+unquote(str-insert($str3, "\\", 1))+unquote("\""); }

/*  jPaginate
 *======================= */
.jPaginate tbody tr:not(.jPaginate-row-1):not(.tblplaceholder) {
	display: none;
}

.jPaginate thead.stick {
	position: fixed;
	top: 0;
	z-index: $zIndex_stickyHeader;
	overflow: hidden;
}

.jPaginate thead.stick tr {
	position: absolute;
}